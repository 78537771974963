// Here you can add other styles
/*-------------------------Variablen----------------------*/
$webtoolColor: rgb(218, 37, 46);
$associateColor: rgb(245, 163, 39);
$morticianColor: rgb(17, 167, 164);

$lightGrey: rgb(237, 237, 237);
$darkGrey: rgb(180, 180, 180);

$invertColor: rgb(255, 255, 255);

html {
  font-size: 62.5%;
}

body {
  overflow-y: hidden;
  background-color: $lightGrey;
  font: 17px/1.52857 'FF Meta Offc W01', sans-serif;
  font-weight: normal;
}

a.btn {
  cursor: pointer;
}
.sidebar {
   position: fixed;
   z-index: 1019;
   width: 200px;
   height: 100vh;
}
.main {
   margin-left: 200px;
}
.footer {
   margin-left: 200px;
}
.sidebar-hidden {
   .footer,
   .main {
      margin-left: 0;
   }
}

/*------------------------- Tables ----------------------*/
.thead-inverse th,
.thead-inverse td{
  color: #e4e5e6;
  background-color: #6d6d6d;
}

.thead-inverse .lighter th,
.thead-inverse .lighter td {
  color: #fff;
  background-color: #9d9d9d;
}

/*-------------------------Boxen Cards Container----------------------*/
.card {
  border: 0;
  margin-top: 3vh;
}

.card-header {
  border-bottom: 0;
  background-color: $darkGrey;
  color: $invertColor;

  h2 {
    display: inline;
    font-size: 2rem;
  }
  a {
    color: $invertColor;
  }
}

.card.administration .card-header {
  background-color: $darkGrey;
}

.card-block {
  a {
    text-align: center;
    display: block;
    margin-bottom: 3vh;
  }
  a:hover {
    text-decoration: none;
    font-weight: 600;
  }
  a:hover .icn {
    background-color: $invertColor;
    border: 1px solid $lightGrey;
  }
}

.icn {
  background-color: $lightGrey;
  text-align: center;

  .figure-img {
    padding: 15px;
  }
  .fa {
    color: $darkGrey;
    font-size: 7rem;
    padding: 20px;
    margin: 0 0 1rem;
  }
}

.caption {
  text-align: center;
  color: $darkGrey;
  background-color: $invertColor;
}

.close .fa {
  color: $invertColor;

}

/*-------------------------Iconset Webtool Font----------------------*/
@font-face {
  font-family: 'webtool-font';
  src: url('font/webtool.eot?41800582');
  src: url('font/webtool.eot?41800582#iefix') format('embedded-opentype'),
  url('font/webtool.woff?41800582') format('woff'),
  url('font/webtool.ttf?41800582') format('truetype'),
  url('font/webtool.svg?41800582#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

.card-header i {
  font-family: "webtool-font";
  font-style: normal;
  font-weight: normal;
  //font-size: 3.5rem;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* You can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.webtool .card-header i:before {
  content: "\E803";
}

.associate .card-header i:before {
  content: "\E800";
}

.mortician .card-header i:before {
  content: "\E801";
}

.app-body {
  height: calc(100vh - 150px);
}

.sidebar .webtool i {
  font-family: "webtool-font";
  font-style: normal;
  font-weight: normal;

  &:before {
    content: "\E803";
  }
}

.sidebar .associate i {
  font-family: "webtool-font";
  font-style: normal;
  font-weight: normal;

  &:before {
    content: "\E800";
  }
}

.sidebar .mortician i {
  font-family: "webtool-font";
  font-style: normal;
  font-weight: normal;

  &:before {
    content: "\E801";
  }
}

.form-control {
  font-size: 17px;
}

/*-------------------------Header-------------------------------------*/
header.navbar {
  height: 60px;
  li {
    padding-right: 2em;
  }
  .navbar-brand {
    height: auto;
    width: auto;
  }
}

/*-------------------------Footer-------------------------------------*/
.footer {
  max-height: 50px;
  a {
    color: $webtoolColor;

  }
}

@media (min-width: 768px) {
  html {
    font-size: 65.5%;
  }
  header.navbar {
    height: $navbar-height;
    .navbar-brand {
      width: $navbar-brand-width;
    }
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 68.5%;
  }
}

@media (min-width: 1250px) {
  html {
    font-size: 71.5%;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 74.5%;
  }
}

/* --- jr --- */
.clickable {
  cursor: pointer;
}
